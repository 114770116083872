.memoria-navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #263B7D;
    height: 120px;
}

.memoria-logo {
    width: 220px;
    height: 72px;
    margin-top: -16px;
}

.memoria-wrapper {
    max-width: 680px;
    margin: 0 auto;
    padding: 2rem 1rem;
    font-family: sans-serif;
}

.progress-bar {
    width: 100%;
    height: 4px;
    background-color: #ddd;
    border-radius: 999px;
    margin: 140px 0 40px;
    position: relative;
}

.progress-fill {
    position: relative;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right,
            #2068C7 0%,
            #2068C7 25%,
            #2068C7 25%,
            transparent 100%);
    border-radius: 999px;
    transition: width 0.5s ease;
}

.progress-fill::after {
    content: '';
    position: absolute;
    right: -5px;
    top: 0;
    width: 20px;
    height: 100%;
    background: linear-gradient(to right, rgba(66, 133, 244, 0.8), transparent);
    filter: blur(8px);
    pointer-events: none;
    z-index: 2;
}


.step h2 {
    text-align: center;
    color: #202020;
    font-size: 48px;
    font-weight: 700;
}

.subtitle {
    text-align: center;
    font-size: 24px;
    color: #20202099;
    margin: 10px 0 60px;
    font-weight: 400;
}

.option {
    width: 100%;
    background-color: #D9D9D980;
    padding: 32px;
    margin-bottom: 1rem;
    border: none;
    border-radius: 32px;
    cursor: pointer;
    font-size: 28px;
    transition: background-color 0.2s;
    color: #202020;
}

.option:hover {
    background-color: #d9d9d9d9;
}

.option.selected {
    background-color: #bdc1c5;
    font-weight: bold;
}

.step div label {
    color: #202020;
    font-size: 24px;
    font-weight: 500;
}

.step div {
    display: flex;
    flex-direction: column;
    gap: 8px
}

.form-ctrl {
    margin: 24px 0
}

input[type='text'],
input[type='email'] {
    width: 100%;
    padding: 32px;
    margin-bottom: 1rem;
    background-color: #D9D9D980;
    border: none;
    border-radius: 32px;
    font-size: 24px;
}

.file-upload {
    display: block;
    margin-bottom: 1rem;
    cursor: pointer;
}

.file-upload input[type='file'] {
    display: none;
}

.upload-box {
    background-color: #D9D9D980;
    border-radius: 32px;
    text-align: center;
    height: 280px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.upload-img-wrapper {
    margin-bottom: 8px;
}

.upload-img-wrapper img {
    width: 38px;
    margin: 0 auto;
}

.checkbox {
    font-size: 0.8rem;
    margin: 1rem 0;
    display: block;
}

.submit-btn {
    max-width: 274px;
    width: 100%;
    background-color: #2068C7;
    color: white;
    font-weight: 700;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-size: 28px;
    height: 80px;
    margin: 0 auto;
}

.submit-btn:hover {
    background-color: #1669c1;
}

.memoria-footer {
    height: 60px;
    background-color: #263B7D;
}